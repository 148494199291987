import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PageLayout } from "../../layouts"
import { Meta } from "../../components"
import { styled } from '../../../stitches.config'
import { SEO } from "../../components/Seo/Seo"

const Container = styled("section", {
  maxWidth: 1000,
  length: 0
})

const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image)

  return (
    <PageLayout>
       <Meta title={data.mdx.frontmatter.title}/>
      <p>Posted: {data.mdx.frontmatter.date}</p>
      <GatsbyImage
        image={image}
        alt={data.mdx.frontmatter.hero_image_alt}
      />
      <p>
        Photo Credit:{" "}
        <a href={data.mdx.frontmatter.hero_image_credit_link}>
          {data.mdx.frontmatter.hero_image_credit_text}
        </a>
      </p>
      <Container>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlogPost

export const Head = () => (
  <SEO title="post"/>
)